import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import Home from './views/Home.vue';
import PageNotFound from './views/PageNotFound.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

class Route {
  private route: RouteConfig;

  constructor(
    public path: string,
    public name: string,
  ) {
    this.route = {
      path,
      name,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ `./views/${name.toUpperCase()}.vue`),
    };
  }

  public getRoute(): RouteConfig {
    return this.route;
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    title: 'Home',
    meta: { title: 'Immobilienberatung in Olten' },
    component: Home,
    sectionName: 'Home',
    navigationEntry: true,
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/mieten-kaufen',
    name: 'rental',
    title: 'Mieten Kaufen',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Rental',
    component: () => import(/* webpackChunkName: "Rental" */ './views/Rental.vue'),
    navigationEntry: true,
  },
  {
    path: '/mieten/:objectId',
    name: 'rental-detail',
    title: 'Mieten Detail',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Rental',
    component: () => import(/* webpackChunkName: "Rental-detail" */ './views/Rental.vue'),
    navigationEntry: true,
  },
  {
    path: '/kaufen',
    name: 'market',
    title: 'Kaufen',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Market',
    component: () => import(/* webpackChunkName: "market" */ './views/Market.vue'),
    navigationEntry: true,
  },
  {
    path: '/referenzen',
    name: 'references',
    title: 'Referenzen',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'References',
    component: () => import(/* webpackChunkName: "references" */ './views/References.vue'),
    navigationEntry: true,
  },
  {
    path: '/kaufen/:objectId',
    name: 'market-detail',
    title: 'Kaufen Detail',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Market',
    component: () => import(/* webpackChunkName: "market-detail" */ './views/Market.vue'),
    navigationEntry: true,
  },
  {
    path: '/ueber-uns',
    name: 'about-us',
    title: 'Über uns',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'About',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    navigationEntry: true,
  },
  {
    path: '/kontakt',
    name: 'contact',
    title: 'Kontakt',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
    navigationEntry: true,
  },
  {
    path: '/downloads',
    name: 'downloads',
    title: 'Downloads',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Downloads',
    component: () => import(/* webpackChunkName: "contact" */ './views/Downloads.vue'),
    navigationEntry: true,
  },
  {
    path: '/bewirtschaftung',
    name: 'management',
    title: 'Bewirtschaftung',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Management',
    component: () => import(/* webpackChunkName: "management" */ './views/Management.vue'),
    navigationEntry: true,
  },
  {
    path: '/rechtsberatung',
    name: 'legal-advice',
    title: 'Rechtsberatung',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'LegalAdvice',
    component: () => import(/* webpackChunkName: "legal-advice" */ './views/LegalAdvice.vue'),
    navigationEntry: true,
  },
  {
    path: '/schaetzung',
    name: 'estimate',
    title: 'Schätzung',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Estimate',
    component: () => import(/* webpackChunkName: "estimates" */ './views/Estimate.vue'),
    navigationEntry: true,
  },
  {
    path: '/erstvermietung',
    name: 'first-letting',
    title: 'Erstvermietung',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'FirstLetting',
    component: () => import(/* webpackChunkName: "firstletting" */ './views/FirstLetting.vue'),
    navigationEntry: true,
  },
  {
    path: '/immobilienvermarktung',
    name: 'real-estate-marketing',
    title: 'Immobilienvermarktung',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'RealEstateMarketing',
    component: () => import(/* webpackChunkName: "realestatemarketing" */ './views/RealEstateMarketing.vue'),
    navigationEntry: true,
  },
  {
    path: '/verkauf',
    name: 'sales',
    title: 'Verkauf',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'Sale',
    component: () => import(/* webpackChunkName: "sales" */ './views/Sale.vue'),
    navigationEntry: true,
  },
  {
    path: '/stockwerkeigentum',
    name: 'storied-property',
    title: 'Stockwerkeigentum',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'StoriedProperty',
    component: () => import(/* webpackChunkName: "storied-property" */ './views/StoriedProperty.vue'),
    navigationEntry: true,
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => { window.location.href = process.env.VUE_APP_CONTACT_FORM || ''; },
  },
  {
    path: '*',
    name: 'page-not-found',
    title: 'Seite nicht gefunden',
    meta: { title: 'Immobilienberatung in Olten' },
    sectionName: 'PageNotFound',
    component: PageNotFound,
    navigationEntry: false,
  },
];

const router = new Router({
  mode: 'history',
  base: '',
  routes,
});

const DEFAULT_TITLE = 'Lüscher Immo AG';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
//
// axios.get('http://luescher-immo.local/actions/route-map/routes/get-all-route-rules?format=Vue')
//   .then((routeRes) => {
//     // router.addRoutes([{}])
//     const craftSections = routeRes.data.sections;
//     ObjectCard.entries(craftSections).forEach((section) => {
//       if (section[0] != null && section[1] != null) {
//         const routeInfo = (section[1] as {
//           handle: string,
//           siteId: string,
//           type: string,
//           url: string,
//           template: string
//         });
//
//         router.addRoutes([{
//           path: routeInfo.url,
//           name: section[0],
//           component: () => import(/* webpackChunkName: "" */ `./views/${section[0]}.vue`),
//         }]);
//       }
//     });
//   });
