import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
  },
  global: {
    ripples: false,
  },
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: colors.blue.darken4,
        accent: '#00A3DD',
        greydark: colors.grey.darken2,
      },
    },
  },
});
