var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-1"},[_c('v-row',[_c('v-col',{class:[
        'text-block',
        'pt-0',
        _vm.textAdjustment,
        { 'hyphens': _vm.hyphens },
        {
          'text-block--image-round': _vm.imageRound,
          'text-block--image-round-small': _vm.imageRoundSmall,
        } ],attrs:{"cols":this.$vuetify.breakpoint.smAndDown ? 12 : _vm.width,"offset":this.$vuetify.breakpoint.smAndDown ? 0 : _vm.offset},domProps:{"innerHTML":_vm._s(_vm.text)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }