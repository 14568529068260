import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    carouselBigMode: false,
    configurations: [],
    activeEntries: [],
    postMessages: {},
    settings: {},
    footer: {},
  },
  getters: {
    getCarousel: (state) => state.carouselBigMode,
    getConfigurations: (state) => state.configurations,
    getActiveEntries: (state) => state.activeEntries,
    getPostMessages: (state) => state.postMessages,
    getSettings: (state) => state.settings,
    getFooter: (state) => state.footer,
  },
  mutations: {},
  actions: {},
});
