






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface TextBlockType {
  settingsText: {
    width: number,
    offset: number,
    textAdjustment: string,
    hyphens: boolean,
  }[];
  imageRound: boolean;
  imageRoundSmall: boolean;
  text: string;
}

@Component({})
export default class TextBlock extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public text: string = '';

  public imageRound: boolean = false;

  public imageRoundSmall: boolean = false;

  public textAdjustment: string = 'text-left';

  public hyphens: boolean = false;

  @Prop({ type: Object })
  private properties!: TextBlockType;

  public static gQlSelect: string = `
    ... on genericContents_TextBlock_BlockType {
      id
      text
      imageRound
      imageRoundSmall
      settingsText {
        ... on settingsText_BlockType {
          width
          offset
          textAdjustment
          hyphens
        }
      }
    }
  `;

  created() {
    this.text = this.properties.text;
    this.imageRound = this.properties.imageRound;
    this.imageRoundSmall = this.properties.imageRoundSmall;
    const settings = this.properties.settingsText[0];

    if (settings != null) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = width;
      this.offset = offset;
      this.textAdjustment = settings.textAdjustment;
      this.hyphens = settings.hyphens;
    }
  }
}
