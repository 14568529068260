

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ContactElem from '@/components/reusable/ContactElem.vue';

interface Consultant {
  id: string,
  firstName: string,
  lastName: string,
  eMail: string,
  funktion: string,
  simpleDescription: string,
  telefonnummer: string,
  photo: {
    id: number,
    url: string,
  }
}

interface CallToActionType {
  settingsCallToAction: {
    width: number,
    offset: number,
    textAdjustment: string,
    hyphens: boolean,
  }[];
  id: number,
  text: string,
  consultant: Consultant[],
}

@Component({
  components: { ContactElem },
})
export default class CallToActionBlock extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public text: string = '';

  public consultants!: Consultant[];

  public textAdjustment: string = 'text-left';

  public hyphens: boolean = false;

  @Prop({ type: Object })
  private properties!: CallToActionType;

  public static gQlSelect: string = `
    ... on genericContents_CallToActionBlock_BlockType {
      id
      text
      consultant {
        ... on User {
          id
          firstName
          lastName
          eMail
          funktion
          simpleDescription
          telefonnummer
          photo {
            ... on profil_Asset {
              id
              url @transform (width: 180, height: 180, mode: "crop", position: "top-right")
            }
          }
        }
      }
      settingsCallToAction {
        ... on settingsCallToAction_BlockType {
          width
          offset
          textAdjustment
          hyphens
        }
      }
    }
  `;

  created() {
    this.text = this.properties.text;
    const consultants = this.properties.consultant;
    const settings = this.properties.settingsCallToAction[0];

    if (consultants) {
      this.consultants = consultants;
    }

    if (settings) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = width;
      this.offset = offset;
      this.textAdjustment = settings.textAdjustment;
      this.hyphens = settings.hyphens;
    }
  }
}
