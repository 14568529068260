


























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Navigation from '@/components/partials/Navigation.vue';
import store from '@/store';
// eslint-disable-next-line no-unused-vars
import { GlobalFooter } from '@/main';

@Component({
  props: ['routes'],
  components: {
    Navigation,
  },
})
export default class Footer extends Vue {
  public allRoutes: [] = [];

  public footer: GlobalFooter = {
    fromName: 'Lüscher Immo AG',
    address: 'Baslerstrasse 30',
    plz: '4601',
    ortschaft: 'Olten',
    eMail: 'info@luescher-immo.ch',
    telefonnummer: '+41 62 212 93 09',
  };

  created() {
    this.allRoutes = (this.$router as any).options.routes;

    store.watch(
      (state, getters) => getters.getFooter,
      (newValue) => {
        this.footer = newValue;
      },
    );
  }
}
