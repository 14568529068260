



























import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/partials/Header.vue';
import Footer from '@/components/partials/Footer.vue';
import Navigation from '@/components/partials/Navigation.vue';

@Component({
  components: {
    Header,
    Navigation,
    Footer,
  },
})
export default class App extends Vue {
  public allRoutes: any[] = [];

  public fab: boolean = false;

  public currentSectionName: string = '';

  created() {
    this.allRoutes = (this.$router as any).options.routes.filter((route) => route.navigationEntry);
    this.$router.beforeEach((to, from, next) => {
      if (to !== from) {
        next();
        setTimeout(() => {
          this.setCurrentSectionName();
        }, 40);
      }
    });
    this.$router.onReady(() => {
      this.setCurrentSectionName();
    });
  }

  public onScroll(e) {
    if (typeof window !== 'undefined') {
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    }
  }

  public toTop() {
    this.$vuetify.goTo(0);
  }

  public setCurrentSectionName() {
    const route = (this.$router as any).options.routes.find((r) => r.name === this.$router.currentRoute.name);
    if (route != null && route.sectionName != null) {
      this.currentSectionName = route.sectionName;
    }
  }
}
