


























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
// import { createSimpleTransition } from 'vuetify/lib/components/transitions';

interface HeaderImage {
  id: number;
  url: string;
}

interface Entry {
  id: number;
  title: string;
  slug: string;
  typeHandle: string;
  sectionHandle: string;
  images: HeaderImage[];
}

interface EntryLinksType {
  settingsEntryLinks: {
    width: number,
    offset: number,
    align: string,
    backgroundColor: string,
  }[];
  id: number;
  entries: Entry[];
}

// const myTransition = createSimpleTransition;
//
// Vue.component('my-transition', myTransition);

@Component({})
export default class EntryLinks extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public carouselIndex: number = 0;

  public entries: Entry[] = [];

  public align: string = 'flex-left';

  public backgroundColor: string = 'transparent';

  public carouselHeight: number = 500;

  @Prop({ type: Object })
  private properties!: EntryLinksType;

  private refHeightDiv!: HTMLDivElement;

  private mainDiv!: HTMLDivElement;

  public getEntries(length): Entry[] {
    const tempEntries = [...this.entries];
    return tempEntries.splice(this.carouselIndex * length, length);
  }

  public static gQlObjectImageElement: string = `
    images(limit: 1) {
      url @transform(height: 300, width: 400, quality: 60)
    }
  `;

  public static gQlHeaderImageElement: string = `
    images: headerImages {
      lazy: url @transform(height: 300, width: 400, quality: 20)
      url @transform(height: 300, width: 400, quality: 60)
    }
  `;

  public static get gQlSelect(): string {
    return `
      ... on genericContents_EntryLinks_BlockType {
        id
        entries {
          id
          title
          slug
          typeHandle
          sectionHandle
          ... on Home_Home_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Rental_Rental_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on RealEstateMarketing_RealEstateMarketing_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Market_Market_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Contact_Contact_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on About_About_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Management_Management_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on StoriedProperty_StoriedProperty_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Estimate_Estimate_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on LegalAdvice_LegalAdvice_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Sale_Sale_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on FirstLetting_FirstLetting_Entry {
            ${this.gQlHeaderImageElement}
          }
          ... on Objects_Rental_Entry {
            id
            ${this.gQlObjectImageElement}
          }
          ... on Objects_Market_Entry {
            id
            ${this.gQlObjectImageElement}
          }
        }
        settingsEntryLinks {
          ... on settingsEntryLinks_BlockType {
            width
            offset
            align
            backgroundColor
          }
        }
      }
    `;
  }

  public firstRouteFragment(entry: Entry) {
    if (entry.sectionHandle === 'Objects') {
      if (entry.typeHandle === 'Rental') {
        return `/mieten/${entry.id}`;
      }

      return `/kaufen/${entry.id}`;
    }
    return `/${entry.slug}`;
  }

  created() {
    this.entries = this.properties.entries;
    const settings = this.properties.settingsEntryLinks[0];

    if (settings != null) {
      const width = +settings.width;
      let offset = +settings.offset;

      if (width + offset > 12) {
        offset = 12 - width;
      }

      this.width = this.$vuetify.breakpoint.xsOnly ? 12 : width;
      this.offset = this.$vuetify.breakpoint.xsOnly ? 0 : offset;
      this.align = settings.align;
      this.backgroundColor = settings.backgroundColor;
    }

    setTimeout(() => {
      const mainRef = document.querySelector<HTMLDivElement>('.entry-links');
      if (mainRef) {
        this.mainDiv = mainRef;
      }

      this.initRefDiv();
      this.handleResize();
    }, 100);

    window.addEventListener('resize', this.handleResize);
  }

  private handleChange() {
    setTimeout(() => {
      this.initRefDiv();
      this.handleResize();
    });
  }

  private handleResize() {
    if (!this.refHeightDiv) {
      this.initRefDiv();
    }
    if (this.refHeightDiv && this.refHeightDiv.getClientRects()[0]) {
      this.carouselHeight = this.refHeightDiv.getClientRects()[0].height + 45;
    }
  }

  private initRefDiv(): void {
    const refList = this.mainDiv.querySelectorAll<HTMLDivElement>('.v-window-item');
    refList.forEach((ref) => {
      if (ref.style.display === '') {
        // eslint-disable-next-line no-param-reassign
        const refElem = ref.querySelector<HTMLDivElement>('.ref-height');
        if (refElem) {
          this.refHeightDiv = refElem;
        }
      }
    });
  }
}
