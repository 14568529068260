












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface SpacerBlockType {
  line: number;
}

@Component({})
export default class SpacerBlock extends Vue {
  public lines: number = 2;

  @Prop({ type: Object })
  private properties!: SpacerBlockType;

  public static gQlSelect: string = `
    ... on genericContents_SpacerBlock_BlockType {
      id
      line
    }
  `;

  created() {
    this.lines = this.properties.line;
  }
}
