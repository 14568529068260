





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface Consultant {
  firstName: string,
  lastName: string,
  eMail: string,
  funktion: string;
  simpleDescription: string,
  telefonnummer: string,
  photo: {
    id: number,
    url: string,
  }
}

@Component({})
export default class CallToAction extends Vue {
  @Prop({ type: Object, required: true })
  public consultant!: Consultant;

  @Prop({ type: Boolean, default: false })
  public small!: boolean;

  public get nameSize(): any {
    if (this.small) {
      return {
        'display-1': true,
      };
    }
    return {
      'display-1': this.$vuetify.breakpoint.xsOnly,
      'display-2': this.$vuetify.breakpoint.smOnly,
      'display-3': this.$vuetify.breakpoint.mdAndUp,
    };
  }

  public get functionSize(): any {
    if (this.small) {
      return {
        heading: true,
      };
    }
    return {
      heading: this.$vuetify.breakpoint.xsOnly,
      headline: this.$vuetify.breakpoint.smAndUp,
    };
  }

  created() {
    this.consultant.telefonnummer = this.consultant.telefonnummer != null ? this.consultant.telefonnummer : '';
  }
}
