
















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
// import { createSimpleTransition } from 'vuetify/lib/components/transitions';
import gql from 'graphql-tag';
// eslint-disable-next-line no-unused-vars
import { GlobalSettings } from '@/main';
import store from '@/store';

interface Slide {
  focalPoint: number[];
  imageXs: string;
  imageSm: string;
  imageLg: string;
  id: number;
  title: string;
  headerTitle: string;
  headerSubTitle: string;
  verlinkungAufSeite: { id: string, uri: string, title: string }[]
}

interface SectionEntry {
  mainHeader: boolean;
  rotationSpeed: number;
  autoRatation: boolean;
  headerImages: Slide[];
}

// const myTransition = createSimpleTransition;
//
// Vue.component('my-transition', myTransition);

@Component({})
export default class Header extends Vue {
  private afterSectionLoaded = 'Home';

  public slides: Slide[] = [];

  public mainHeader: boolean = false;

  public autoRotate: boolean = true;

  public rotationSpeed: number = 16000;

  public height(subtract: number = 0): string {
    if (this.$vuetify.breakpoint.xsOnly) {
      return `${300 - subtract}px`;
    }
    if (this.$vuetify.breakpoint.lgAndUp) {
      return `${600 - subtract}px`;
    }
    return (this.afterSectionLoaded === 'Home' || this.mainHeader) ? `calc(32vw + ${150 - subtract}px)` : `${300 - subtract}px`;
  }

  public scrollContentDivs!: NodeListOf<HTMLElement>;

  public settings: GlobalSettings = {
    shareText: 'Siehe dir dieses interessante Angebot von Lüscher Immo AG an.',
    headerArrows: false,
    headerBilderKurzwahl: false,
  };

  @Prop({ type: String })
  public section!: string;

  public get validSectionName(): string {
    return this.section ? this.section : '';
  }

  public calculateFocalPoint(focal: number[]): string {
    return focal.map((point) => `${point * 100}%`).join(' ');
  }

  public carouselChanged(event): void {
    this.initParallax(event);
  }

  public getBackgroundImageUrl(slide: Slide): string {
    const bp = this.$vuetify.breakpoint;

    if (bp.xs) {
      return slide.imageXs;
    }

    if (bp.sm || bp.md) {
      return slide.imageSm;
    }

    return slide.imageLg;
  }

  @Watch('section')
  public sectionChanged() {
    this.getHeaderImages();
  }

  private getHeaderImages() {
    if (this.validSectionName.length > 0) {
      this.$apollo.query<{entry: SectionEntry}>({
        query: gql`query{
          entry(section: "${this.validSectionName}") {
            ... on ${this.validSectionName}_${this.validSectionName}_Entry {
              rotationSpeed
              mainHeader
              autoRotation
              headerImages {
                id
                imageXs: url @transform(width: 800, quality: 50)
                imageSm: url @transform(width: 1300, quality: 50)
                imageLg: url @transform(width: 2000, quality: 45)
                title
                focalPoint
                ... on headerImages_Asset {
                  headerTitle
                  headerSubTitle
                }
              }
            }
          }
        }`,
      }).then((data) => {
        if (data.data.entry != null && data.data.entry) {
          const sectionEntry = data.data.entry;
          this.rotationSpeed = sectionEntry.rotationSpeed * 1000 || 16000;
          this.autoRotate = sectionEntry.autoRatation || true;
          this.slides = data.data.entry.headerImages;
          this.mainHeader = data.data.entry.mainHeader || false;
          this.afterSectionLoaded = this.validSectionName;

          setTimeout(() => {
            this.initParallax(0);
          }, 100);
        }
      });
    }
  }

  created() {
    store.watch(
      (state, getters) => getters.getSettings,
      (newValue) => {
        this.settings = newValue;
      },
    );
    this.getHeaderImages();
  }

  private initParallax(changeIndex: number) {
    const scrollContentDivs = document.querySelectorAll<HTMLDivElement>('.parallax-scroll');
    if (scrollContentDivs != null && scrollContentDivs.length > 0) {
      this.scrollContentDivs = scrollContentDivs;
      window.addEventListener('scroll', (event) => {
        this.handleContainerScroll(event, changeIndex);
      }, { passive: true });
    }
  }

  private handleContainerScroll(event: Event, changeIndex): void {
    const scrollContainer = event.target as HTMLDivElement;
    if (scrollContainer != null && this.scrollContentDivs[changeIndex]) {
      const container = this.scrollContentDivs[changeIndex];
      if (container.parentElement && container.parentElement.getClientRects()[0]) {
        const scrollPixelPercentage = container.parentElement.getClientRects()[0].height / 80;
        container.style.marginTop = `${window.scrollY / scrollPixelPercentage}px`;
      }
    }
  }
}
