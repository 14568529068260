






























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface MootIconType {
  id: string;
  mootIcon: string;
}

@Component({})
export default class MootIcon extends Vue {
  public width: number = 12;

  public offset: number = 0;

  public mootIcon: string = '';

  @Prop({ type: Object })
  private properties!: MootIconType;

  public static gQlSelect: string = `
    ... on genericContents_MootIcon_BlockType {
      id
      mootIcon
    }
  `;

  created() {
    this.mootIcon = this.properties.mootIcon;
  }
}
