var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:[
    'pb-0',
    'contact-elem' ,
    { 'contact-elem--small': _vm.small }
  ]},[_c('v-row',[_c('v-col',{staticClass:"contact-elem__description-small",attrs:{"cols":12}},[_c('span',{class:[
            'pt-2',
            'contact-elem__function',
            'font-weight-light',
            _vm.functionSize,
            'font-italic'
          ]},[_vm._v(_vm._s(_vm.consultant.funktion))]),_c('br'),_c('span',{class:['contact-elem__name', 'font-weight-light', _vm.nameSize]},[_vm._v(" "+_vm._s(_vm.consultant.firstName)+" "+_vm._s(_vm.consultant.lastName)+" ")])]),_c('v-col',{staticClass:"contact-elem__description",attrs:{"cols":6}},[_c('div',[_c('span',{class:[
            'pt-2',
            'contact-elem__function',
            'font-weight-light',
            _vm.functionSize,
            'font-italic'
          ]},[_vm._v(_vm._s(_vm.consultant.funktion))]),_c('br'),_c('span',{class:['contact-elem__name', 'font-weight-light', _vm.nameSize]},[_vm._v(" "+_vm._s(_vm.consultant.firstName)+" "+_vm._s(_vm.consultant.lastName)+" ")]),_c('div',{staticClass:"contact-elem__description-bottom"},[_c('p',{staticClass:"contact-elem__simple-description"},[_vm._v(" "+_vm._s(_vm.consultant.simpleDescription)+" ")]),_c('a',{staticClass:"mail-to",attrs:{"href":("tel:" + (_vm.consultant.telefonnummer))}},[_vm._v(_vm._s(_vm.consultant.telefonnummer))]),_c('a',{staticClass:" mail-to",attrs:{"href":("mailto:" + (_vm.consultant.eMail))}},[_vm._v(_vm._s(_vm.consultant.eMail))])])])]),_c('v-col',{staticClass:"contact-elem__image-wrapper",attrs:{"cols":6}},[_c('div',{staticClass:"contact-elem__image",style:({backgroundImage: ("url(" + (_vm.consultant.photo.url) + ")")})})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }