




import { Component, Vue } from 'vue-property-decorator';
import 'vue-apollo';
import GenericContent from '@/components/content/GenericContent.vue';

@Component({
  components: {
    GenericContent,
  },
})
export default class Home extends Vue {

}
